<template>
  <div>
    <vuestic-widget :loading="loading">
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">
            Training
          </span>
          <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-default btn-primary btn-sm" @click="editPage('training')">
              Edit Training
            </button>
          </div>
        </div>
      </template>
      <div v-if="page" v-html="page.content"></div>
    </vuestic-widget>

    <vuestic-modal
      class="edit-page-modal"
      :isOpen="isOpenEditPage"
      @ok="handleSavePage"
      @cancel="closeModalEditPage"
      okText="Save"
      :closeOnOk="false"
      cancelText="Cancel"
    >
      <span slot="title">Edit page</span>
      <div v-if="selectedPage">
        <text-input label="Key" v-model="selectedPage.key" name="key" disabled></text-input>
        <text-input label="Title" v-model="selectedPage.title" name="title"></text-input>
        <textarea ref="editor"></textarea>
      </div>
    </vuestic-modal>
  </div>
</template>
<script>
// froala-editor
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

export default {
  data () {
    return {
      page: null,
      loading: false,
      selectedPage: null,
      isOpenEditPage: false,
      editor: null,
      editorInitialized: false,
      editorConfig: {
        key: process.env.VUE_APP_FROALA_API_KEY,
        heightMin: 300,
        // heightMax: 450,
        quickInsertEnabled: false,
        toolbarVisibleWithoutSelection: false,
        useClasses: false,
        enter: FroalaEditor.ENTER_BR,
        fontFamilySelection: true,
        fontSizeSelection: true,
        linkAlwaysBlank: true,
        linkStyles: {
          'fr-green': 'Green',
          'fr-strong': 'Thick',
          'underlineClass': 'Underline',
        },
        toolbarButtons: {
          'moreText': {
            'buttons': [
              'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'alignLeft', 
              'alignCenter', 'alignRight', 'alignJustify', 'insertLink', 'insertImage', 'emoticons', 'formatUL', 'formatOL', 
              'lineHeight', 'strikeThrough', 'paragraphFormat', 'paragraphStyle', 'insertHR', 'outdent', 'indent', 'quote', 'undo', 'redo', 'fullscreen', 'html'
            ],
            'buttonsVisible': 10,
          },
        },
        emoticonsUseImage: false,
        requestHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        imageUpload: true,
        imageUploadMethod: 'POST',
        imageUploadParam: 'image',
        imageUploadParams: {
          link: true,
        },
        imageUploadRemoteUrls: true,
        imageUploadURL: process.env.VUE_APP_BASE_API + 'api/v1/image',
        ...this.options,
        events: {
          initialized: () => {
            this.editor.html.set(this.selectedPage.content)
            this.initEvents()
          },
          blur: () => { 
            this.editor.selection.save();
          },
          destroy: () => { }
        },
      }
    }
  },

  mounted () {
    this.getPage('training')
  },

  methods: {
    initEditor() {
      if (!this.editorInitialized) {
        this.editor = new FroalaEditor(this.$refs.editor, this.editorConfig)
        this.editorInitialized = true
      }
    },
    initEvents() {
      let _this = this
      _this.editor.events.on('contentChanged', function (e) {
        if (_this.selectedPage.content !== _this.editor.html.get()) {
          _this.selectedPage.content = _this.editor.html.get()
        }
      }, false);

    },
    getPage (key) {
      this.loading = true

      this.$store.dispatch('page/getPage', key)
        .then((res) => {
          this.loading = false
          this.page = res.data.data
        })
        .catch(() => {
          this.loading = false
        })
    },

    editPage (key) {
      this.$store.dispatch('page/getPage', key)
        .then((res) => {
          this.isOpenEditPage = true
          this.selectedPage = res.data.data
          if (this.editor) {
            this.editor.html.set(this.selectedPage.content)
          }
          this.$nextTick(() => this.initEditor());
        })
        .catch(() => {
        })
    },

    handleSavePage () {
      this.loading = true
      this.$store.dispatch('page/updatePage', this.selectedPage)
        .then((res) => {
          this.getPage('training')
          this.closeModalEditPage()
        })
        .catch(() => {
          this.loading = false
        })
    },

    closeModalEditPage () {
      this.isOpenEditPage = false
    },
  }
}
</script>